<template>
  <textarea class="p-2 border w-full h-40" v-model="value" />
</template>
<script>
export default {
  name: "PreviewAnswer",
  props: {
    notReadyToSubmit: {
      type: Boolean,
      required: false
    },
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    value: {
      set(val) {
        this.$set(this.item, "single_value", val);
      },
      get() {
        return this.item?.single_value;
      }
    }
  }
};
</script>
