<template>
  <div>
    <div class="flex flex-wrap">
      <span
        class="rounded mx-1 mb-1 px-2 cursor-pointer"
        v-for="(option, index) in options"
        :key="`option${index}-${option.value}`"
        @click="selectedOption(option)"
        :class="{
          'bg-blue-500 text-blue-50': option.value == item.single_value,
          'border border-blue-500 text-blue-500':
            option.value != item.single_value
        }"
      >
        {{ option.value }}
      </span>
    </div>
    <PreviewSolutions
      :solutions="selected.solutions"
      v-if="
        selected &&
        item.custom_data.showSolutions &&
        selected.solutions.length > 0
      "
    />
  </div>
</template>

<script>
import PreviewSolutions from "./PreviewSolutions.vue";

export default {
  name: "PreviewRadio",
  props: {
    item: {
      type: Object,
      require: true
    }
  },
  components: { PreviewSolutions },
  computed: {
    options() {
      return this.item.custom_data?.options
        ? this.item.custom_data?.options
        : [];
    }
    // selectedValue() {
    //   return this.selected == null ? null : this.selected.value;
    // }
  },
  methods: {
    selectedOption(option) {
      this.$set(this.item, "single_value", option.value);
      this.selected = option;
      // this.$set(this.item, "pass", option.value);
      // props.item.solutions_selected = [];
      // props.item.solutions_rejected = [];
      // selectedOption.value = option;
      // props.item.single_value = option.value;
      // props.item.pass =
      //   option.solutions && option.solutions.length > 0 ? false : true;
    }
  },
  data() {
    return {
      selected: null
    };
  }
};
</script>
