<template>
  <div>
    <div
      @click="value = !value"
      class="text-lg cursor-pointer"
      :class="{
        'text-green-600': value,
        'text-coolGray-400': !value
      }"
    >
      <i v-if="value" class="fas fa-check-circle" />
      <i v-if="!value" class="far fa-circle" />
      <span
        :class="{
          'text-rose-500 my-2 border-b-4 border-rose-500 ': hasError
        }"
      >
        {{ name }}
      </span>
      <i
        class="fas fa-asterisk text-rose-500 text-xs"
        v-if="item.custom_data.required"
      />
    </div>
    <p
      v-html="description"
      class="text-xs ml-6 text-slate-400 mb-1 -mt-1 rounded"
    />
  </div>
</template>

<script>
export default {
  name: "PreviewCheckoff",
  props: {
    item: {
      type: Object,
      required: true
    },
    hasError: {
      type: Boolean,
      default: false
    }
    // name: {
    //   type: String,
    //   require: true
    // },
    // custom_data: {
    //   type: Object,
    //   require: true
    // }
  },
  data() {
    return { pass: false };
  },
  computed: {
    name() {
      return this.item.name;
    },
    value: {
      set(val) {
        this.$set(this.item, "pass", val);
      },
      get() {
        return this.item?.pass ? true : false;
      }
    },
    description() {
      const str = this.custom_data?.description;
      if (str) {
        const urlRegex = /(https?:\/\/[^\s]+)/g;

        return str
          .replace(/<[^>]*>?/gm, "")
          .replace(urlRegex, '<a href="$1" class="text-indigo-700">$1</a>');
      }
      return "";
    }
  }
};
</script>
