<template>
  <div
    :class="{
      'px-8': !smallScreen
    }"
  >
    <span class="text-gray-400 font-semibold" v-if="!smallScreen">
      {{ $t("medias") }}
    </span>

    <span class="text-xs text-orange-600 font-semibold" v-if="smallScreen">
      {{ $t("attached medias") }}
    </span>

    <div
      :class="{
        'md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6': !smallScreen,
        'md:grid-cols-3': smallScreen
      }"
      class="grid grid-cols-1 gap-4 mt-3"
    >
      <div
        class="relative"
        v-for="(media, index) in medias"
        :key="`${index}-${media.content_type}`"
      >
        <img
          @click="startFullScreen(media)"
          v-if="isImage(media)"
          class="rounded-lg object-cover w-full"
          :src="media.path"
        />
        <div v-else-if="isVideo(media)" class="relative">
          <video
            @click="startFullScreen(media)"
            height="100%"
            class="rounded-lg object-cover w-full"
          >
            <!-- <video
          @click="startFullScreen(media)"
          height="100%"
          class="rounded-lg object-cover w-full"
          v-else-if="isVideo(media)"
          controls
        > -->
            <source :src="media.path" type="video/mp4" />
          </video>
          <div
            class="absolute w-full h-full z-50 top-0 left-0"
            @click="startFullScreen(media)"
          />
        </div>

        <a v-else :href="media.path" download>
          <i class="fas fa-file-pdf" /> {{ media.filename }}
        </a>

        <i
          v-if="hasRemove"
          class="fas fa-times-circle absolute -right-2 -top-2 text-xl text-orange-600 bg-white rounded-full h-5 cursor-pointer"
          @click="removeMedia(index)"
        />
        <el-popconfirm
          v-if="hasRemoveById"
          :title="`${$t('Confirm')} ${$t('delete')} ?`"
          @confirm="removeMediaById(media.id)"
        >
          <i
            slot="reference"
            class="fas fa-times-circle absolute -right-2 -top-2 text-xl text-orange-600 bg-white rounded-full h-5 cursor-pointer"
          />
        </el-popconfirm>
      </div>
    </div>

    <el-dialog :visible.sync="mediaDialog" height="80vh" :fullscreen="true">
      <div class="w-full items-center flex justify-center">
        <img
          v-if="mediaDialog && isImage(selectedMedia)"
          class=""
          :src="selectedMedia.path"
        />
        <video
          v-if="mediaDialog && isVideo(selectedMedia)"
          class="rounded-lg"
          style="height: 70vh"
          controls
        >
          <source :src="selectedMedia.path" type="video/mp4" />
        </video>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "MediaGallery",
  props: {
    smallScreen: {
      type: Boolean,
      default: false
    },
    medias: {
      type: Array,
      required: true
    },
    hasRemove: {
      type: Boolean,
      default: false
    },
    hasRemoveById: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      mediaDialog: false,
      selectedMedia: ""
    };
  },
  methods: {
    startFullScreen(media) {
      this.mediaDialog = true;
      this.selectedMedia = media;
    },
    removeMedia(index) {
      this.$emit("removeMedia", index);
    },
    removeMediaById(id) {
      this.$emit("removeMediaById", id);
    },
    isType({ content_type }, mediaType) {
      return content_type.includes(mediaType);
    },
    isImage(media) {
      return media ? this.isType(media, "image") : false;
    },
    isVideo(media) {
      return media ? this.isType(media, "video") : false;
    }
  }
};
</script>
