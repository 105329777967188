var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"flex flex-wrap"},_vm._l((_vm.options),function(option,index){return _c('span',{key:("option" + index + "-" + (option.value)),staticClass:"rounded mx-1 mb-1 px-2 cursor-pointer",class:{
        'bg-blue-500 text-blue-50': option.value == _vm.item.single_value,
        'border border-blue-500 text-blue-500':
          option.value != _vm.item.single_value
      },on:{"click":function($event){return _vm.selectedOption(option)}}},[_vm._v(" "+_vm._s(option.value)+" ")])}),0),(
      _vm.selected &&
      _vm.item.custom_data.showSolutions &&
      _vm.selected.solutions.length > 0
    )?_c('PreviewSolutions',{attrs:{"solutions":_vm.selected.solutions}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }