<template>
  <div class="flex flex-col">
    <div
      class="rounded mx-1 px-2 text-lg cursor-pointer"
      v-for="(option, index) in options"
      :key="`option${index}-${option.value}`"
      @click="selectOption(option)"
      :class="{
        'text-emerald-600': multi_value && multi_value.includes(option.value)
      }"
    >
      <i
        class="fas fa-check-square"
        v-if="multi_value && multi_value.includes(option.value)"
      />
      <i
        class="far fa-square"
        v-if="!multi_value || !multi_value.includes(option.value)"
      />
      {{ option.value }}

      <PreviewSolutions
        :solutions="option.solutions"
        v-if="
          multi_value[option.value] == true &&
          custom_data.showSolutions &&
          option.solutions.length > 0
        "
      />
    </div>
  </div>
</template>
<script>
import PreviewSolutions from "./PreviewSolutions.vue";

export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  components: { PreviewSolutions },
  computed: {
    options() {
      return this.item.custom_data?.options
        ? this.item.custom_data?.options
        : [];
    },
    multi_value() {
      return this.item?.multi_value ? this.item?.multi_value : [];
    }
  },
  methods: {
    selectOption(option) {
      if (this.item?.multi_value == undefined)
        this.$set(this.item, "multi_value", []);
      const index = this.item.multi_value.findIndex(v => v == option.value);
      if (index == -1) {
        this.item.multi_value.push(option.value);
      } else {
        this.item.multi_value.splice(index, 1);
      }
      this.$set(this.item, "pass", option.solutions.length > 0 ? false : true);
    }
  }
};
</script>
