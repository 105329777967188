var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._l((_vm.item.medias),function(media,index){return _c('div',{key:((media.id) + "-media"),staticClass:"w-full",on:{"click":function($event){_vm.currentActiveMediaIndex = index}}},[(index == _vm.currentActiveMediaIndex)?_c('img',{staticClass:"w-full rounded-t object-cover max-h-36",attrs:{"src":media.path}}):_vm._e()])}),_c('div',{staticClass:"flex flex-col rounded-lg overflow-hidden bg-white py-4 px-6"},[_c('div',{staticClass:"flex flex-row justify-between items-center"},[(_vm.item.custom_data.type != 'check')?_c('h3',{staticClass:"relative text-lg font-semibold text-slate-600"},[_c('span',{class:{
            '-ml-6 px-6 border-l-4 border-green-500':
              _vm.item.custom_data.type == 'heading',
            'text-rose-500 my-2 border-b-4 border-rose-500 ': _vm.hasError
          }},[_vm._v(" "+_vm._s(_vm.item.name)+" ")]),(_vm.item.custom_data.required)?_c('i',{staticClass:"fas fa-asterisk text-rose-500 text-xs"}):_vm._e(),_c('p',{staticClass:"text-xs ml-1 text-slate-400 mb-1 -mt-1 rounded",domProps:{"innerHTML":_vm._s(_vm.urlify(_vm.item.custom_data.description))}})]):_vm._e(),(_vm.item.custom_data.canAttachMedia)?_c('div',[_c('input',{ref:"uploadFile",attrs:{"id":("uploadFile" + (_vm.item.id)),"type":"file","accept":"media_type","multiple":"","hidden":""},on:{"change":_vm.fileChange}}),_c('label',{staticClass:"font-bold text-xs px-2 py-1 bg-emerald-700 text-emerald-50 rounded transition-all duration-150",attrs:{"for":("uploadFile" + (_vm.item.id))}},[_vm._v(" "+_vm._s(_vm.$t("attach media"))+" ")])]):_vm._e()]),(_vm.item.custom_data.type == 'answer')?_c('PreviewAnswer',{attrs:{"item":_vm.item}}):_vm._e(),(_vm.item.custom_data.type == 'dateTime')?_c('PreviewDatetimePicker',{attrs:{"item":_vm.item}}):_vm._e(),(
        _vm.item.custom_data.type == 'temp' || _vm.item.custom_data.type == 'number'
      )?_c('PreviewNumber',{attrs:{"item":_vm.item}}):_vm._e(),(_vm.item.custom_data.type == 'signature')?_c('PreviewSignature',{attrs:{"item":_vm.item}}):_vm._e(),(_vm.item.custom_data.type == 'check')?_c('PreviewCheckoff',{attrs:{"item":_vm.item,"hasError":_vm.hasError}}):_vm._e(),(
        _vm.item.custom_data.type == 'radio' ||
        _vm.item.custom_data.type == 'option' ||
        _vm.item.custom_data.type == 'yesno'
      )?_c('PreviewRadio',{attrs:{"item":_vm.item}}):_vm._e(),(_vm.item.custom_data.type == 'checkbox')?_c('PreviewCheckbox',{attrs:{"item":_vm.item}}):_vm._e(),(_vm.item.custom_data.canAttachMedia)?_c('MediaGallery',{attrs:{"medias":_vm.files,"smallScreen":true,"hasRemove":true},on:{"removeMedia":_vm.removeMedia}}):_vm._e()],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }