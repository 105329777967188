<template>
  <div class="px-3 py-4 my-2 bg-emerald-50 text-coolGray-400">
    <h3 class="font-bold text-emerald-900">{{ $t("solutions") }}</h3>
    <div
      class="flex items-center"
      v-for="(solution, i) in solutions"
      :key="`${solution.name}-${i}`"
    >
      <i
        :class="{
          'text-teal-700': selected[solution.name] == true
        }"
        @click="selectSolution(solution, $event)"
        class="fas fa-check-circle mr-1 text-xl"
      />
      <i
        :class="{
          'text-rose-700': selected[solution.name] == false
        }"
        @click="rejectSolution(solution, $event)"
        class="fas fa-times-circle mr-1 text-xl"
      />
      {{ solution.name }}
    </div>
  </div>
</template>

<script>
export default {
  name: "PreviewSolutions",
  props: {
    solutions: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selected: {}
    };
  },
  methods: {
    selectSolution(solution, evt) {
      evt.stopPropagation();
      const select = this.selected[solution.name] == true ? null : true;
      this.$set(this.selected, solution.name, select);
    },
    rejectSolution(solution, evt) {
      evt.stopPropagation();
      const select = this.selected[solution.name] == false ? null : false;
      this.$set(this.selected, solution.name, select);
    }
  }
};
</script>
