<template>
  <div class="flex items-center border p-2 rounded">
    <i
      v-if="item.custom_data.type == 'temp'"
      class="fas fa-thermometer-quarter text-xl mx-4 text-slate-600"
    />
    <input
      v-model.number="value"
      class="w-full outline-none px-4 py-2"
      type="number"
      :min="item.custom_data.min"
      :max="item.custom_data.max"
    />
    <p
      v-if="item.custom_data.unit"
      class="font-semibold text-slate-500 break-normal pr-2"
    >
      [{{ item.custom_data.unit }}]
    </p>
  </div>
</template>

<script>
export default {
  name: "PreviewNumber",
  props: {
    item: {
      type: Object,
      require: true
    }
  },
  computed: {
    value: {
      set(val) {
        this.$set(this.item, "single_number_value", val);
      },
      get() {
        return this.item?.single_number_value;
      }
    }
  }
};
</script>
