<template>
  <el-date-picker v-model="value" :type="dateOnly" :placeholder="placeholder" />
</template>
<script>
export default {
  name: "PreviewDatetimePicker",
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    dateOnly() {
      return this.item?.custom_data?.dateOnly ? "date" : "datetime";
    },
    placeholder() {
      return this.dateOnly == "date"
        ? this.$t("Select date")
        : this.$t("Select date and time");
    },
    value: {
      set(val) {
        this.$set(this.item, "datetime", val);
      },
      get() {
        return this.item?.datetime;
      }
    }
  }
};
</script>
