<template>
  <div>
    <div
      class="w-full"
      v-for="(media, index) in item.medias"
      :key="`${media.id}-media`"
      @click="currentActiveMediaIndex = index"
    >
      <img
        class="w-full rounded-t object-cover max-h-36"
        v-if="index == currentActiveMediaIndex"
        :src="media.path"
      />
      <!-- v-if="media.content_type.includes(`image`)" -->
    </div>
    <div class="flex flex-col rounded-lg overflow-hidden bg-white py-4 px-6">
      <div class="flex flex-row justify-between items-center">
        <h3
          v-if="item.custom_data.type != 'check'"
          class="relative text-lg font-semibold text-slate-600"
        >
          <span
            :class="{
              '-ml-6 px-6 border-l-4 border-green-500':
                item.custom_data.type == 'heading',
              'text-rose-500 my-2 border-b-4 border-rose-500 ': hasError
            }"
          >
            {{ item.name }}
          </span>

          <i
            class="fas fa-asterisk text-rose-500 text-xs"
            v-if="item.custom_data.required"
          />
          <p
            v-html="urlify(item.custom_data.description)"
            class="text-xs ml-1 text-slate-400 mb-1 -mt-1 rounded"
          />
        </h3>
        <div v-if="item.custom_data.canAttachMedia">
          <input
            :id="`uploadFile${item.id}`"
            type="file"
            ref="uploadFile"
            @change="fileChange"
            accept="media_type"
            multiple
            hidden
          />
          <label
            class="font-bold text-xs px-2 py-1 bg-emerald-700 text-emerald-50 rounded transition-all duration-150"
            :for="`uploadFile${item.id}`"
          >
            {{ $t("attach media") }}
          </label>
        </div>
      </div>
      <PreviewAnswer v-if="item.custom_data.type == 'answer'" :item="item" />
      <PreviewDatetimePicker
        v-if="item.custom_data.type == 'dateTime'"
        :item="item"
      />
      <PreviewNumber
        :item="item"
        v-if="
          item.custom_data.type == 'temp' || item.custom_data.type == 'number'
        "
      />
      <PreviewSignature
        v-if="item.custom_data.type == 'signature'"
        :item="item"
      />
      <PreviewCheckoff
        v-if="item.custom_data.type == 'check'"
        :item="item"
        :hasError="hasError"
      />
      <PreviewRadio
        v-if="
          item.custom_data.type == 'radio' ||
          item.custom_data.type == 'option' ||
          item.custom_data.type == 'yesno'
        "
        :item="item"
      />
      <PreviewCheckbox
        v-if="item.custom_data.type == 'checkbox'"
        :item="item"
      />
      <MediaGallery
        v-if="item.custom_data.canAttachMedia"
        :medias="files"
        :smallScreen="true"
        :hasRemove="true"
        @removeMedia="removeMedia"
      />
    </div>
  </div>
</template>

<script>
import PreviewNumber from "./preview/PreviewNumber.vue";
import PreviewAnswer from "./preview/PreviewAnswer.vue";
import PreviewRadio from "./preview/PreviewRadio.vue";
import PreviewCheckoff from "./preview/PreviewCheckoff.vue";
import MediaGallery from "../MediaGallery.vue";
import PreviewSignature from "./preview/PreviewSignature.vue";
import PreviewCheckbox from "./preview/PreviewCheckbox.vue";
import PreviewDatetimePicker from "./preview/PreviewDatetimePicker.vue";
import utils from "../../mixins/utils";
export default {
  name: "PreviewComponent",
  mixins: [utils],
  components: {
    PreviewNumber,
    PreviewAnswer,
    PreviewRadio,
    MediaGallery,
    PreviewCheckoff,
    PreviewDatetimePicker,
    PreviewSignature,
    PreviewCheckbox
  },
  props: {
    item: {
      type: Object,
      require: true
    },
    notReadyToSubmit: {
      type: Boolean,
      require: false
    }
  },
  computed: {
    hasError() {
      const {
        single_value,
        multi_value,
        pass,
        datetime,
        custom_data,
        single_number_value,
        signatureBase64
      } = this.item;
      if (!this.notReadyToSubmit) return false;

      if (custom_data?.type == "number" || custom_data?.type == "temp") {
        if (isNaN(single_number_value)) return true;
      }

      if (custom_data?.required) {
        if (
          custom_data.type == "option" ||
          custom_data.type == "radio" ||
          custom_data.type == "answer" ||
          custom_data.type == "yesno"
        ) {
          return single_value == undefined || single_value == "";
        }
        if (custom_data.type == "dateTime") {
          return !datetime;
        }
        if (custom_data.type == "checkbox") {
          return multi_value == undefined || multi_value.length == 0;
        }
        if (custom_data?.type == "check") {
          return pass == undefined || pass == false;
        }
        if (custom_data?.type == "number" || custom_data?.type == "temp") {
          return single_number_value == undefined || single_number_value == "";
        }
        if (custom_data?.type == "signature") {
          return (
            signatureBase64 == undefined ||
            signatureBase64 == "" ||
            signatureBase64 == null
          );
        }
      }
      return false;
    }
  },
  data() {
    return {
      dtime: null,
      files: [],
      currentActiveMediaIndex: 0
    };
  },
  methods: {
    fileChange() {
      this.files = Array.from(this.$refs.uploadFile.files).map(file => ({
        content_type: file.type,
        path: URL.createObjectURL(file)
      }));
    },
    removeMedia(index) {
      this.files = Array.from(this.files).filter((_, i) => i != index);
    }
  }
};
</script>
