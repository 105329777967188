<template>
  <div>
    <div class="border border-gray-200 h-32 w-full rounded">
      <VueSignaturePad ref="signaturePad" :options="{ onEnd }" />
    </div>
    <div class="text-right text-red-500 uppercase">
      <button
        @click="clear"
        class="clear-btn uppercase border rounded mt-1 border-red-500 px-2"
      >
        {{ $t("Clear") }}
      </button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      pad: null
    };
  },
  methods: {
    onEnd() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      // console.log(data);
      // console.log(isEmpty);
      if (!isEmpty) {
        this.$set(this.item, "signatureBase64", data);
      }
    },
    clear() {
      this.$refs.signaturePad.clearSignature();
    }
  }
};
</script>
